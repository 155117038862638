<template>
    <vx-card :title="'Work Order Execution - Putaway'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order Description</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Bill Of Material ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="warehouseName" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 10%" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle; width: 30%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Actual Qty</th>
                            <th style="padding: 5px; width: 2%" rowspan="2"></th>
                            <th style="text-align: center;" colspan="3">Put-away</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in parentLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.sku_code" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length"></td>

                                <!-- first line -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input v-model="tr.putaway_lines[0].storage_area" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input v-model="tr.putaway_lines[0].batch" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="formatDate(tr.putaway_lines[0].expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(trx, indexPutaway) in tr.putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexPutaway" v-if="indexPutaway > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br><h3 v-if="leftOverLines.length > 0">Leftover Items</h3><hr><br>
        <div v-if="leftOverLines.length > 0" class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 10%" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle; width: 30%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="padding: 5px; width: 2%" rowspan="2"></th>
                            <th style="text-align: center;" colspan="3">Put-away</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in leftOverLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.sku_code" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length"></td>

                                <!-- first putaway lines -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.putaway_lines[0].storage_area" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.putaway_lines[0].batch" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.putaway_lines[0].expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(trx, indexPutaway) in tr.putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexPutaway" v-if="indexPutaway > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseName: null,
            parentLines: [
                {
                    sku_code: null,
                    item_name: null,
                    option_warehouse_area: [],
                    option_batch: [],
                    option_exp_date: [],
                    unit: null,
                    qty: 0,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            storage_area: null,
                            batch: null,
                            expired_date: null,
                        }
                    ]
                }
            ],
            leftOverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    option_warehouse_area: [],
                    option_batch: [],
                    option_exp_date: [],
                    unit: null,
                    qty: 0,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            storage_area: null,
                            batch: null,
                            expired_date: null,
                        }
                    ]
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/work-order-execution/putaway-form/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.woCode = resp.data.wo_code
                        this.description = resp.data.wo_description
                        this.bomCode = resp.data.bom_code
                        this.warehouseID = resp.data.warehouse_id
                        this.warehouseName = resp.data.warehouse_name
                        this.parentLines = resp.data.parent_lines
                        this.leftOverLines = resp.data.leftover_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: "work-order-execution",
            });
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>